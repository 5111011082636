import constants from "../constants/constants";
import { getAuthData, getRolePowers, getPermissions } from "./AuthUtil";
import { is_empty } from "./validations";

let modifyItemsObj = null;
let navigationHiddenItemsObj = null;
let visibleFeatures = {};

export const orgPermissions = {
  get rolePowers() {
    return JSON.parse(sessionStorage.getItem("role_powers"));
  },

  init: async () => {
    await getRolePowers();

    if (
      // if we dont have menu permissions but the user is logged in, we need to fetch menu permissions
      !sessionStorage.getItem("menu_permissions") &&
      !is_empty(getAuthData())
    ) {
      await getPermissions();
    }

    const modifyItemsSessionItem = JSON.parse(
      sessionStorage.getItem("menu_permissions")
    );

    if (modifyItemsSessionItem) {
      modifyItemsObj = modifyItemsSessionItem.modify_items;
      navigationHiddenItemsObj = modifyItemsSessionItem.hidden_items;
      visibleFeatures = modifyItemsSessionItem.visible_items;
    }
  },

  clear: () => {
    modifyItemsObj = null;
    navigationHiddenItemsObj = null;
    visibleFeatures = {};
  },

  isTabHidden: (section, subSection, tab) => {
    if (!modifyItemsObj) return false;

    if (
      modifyItemsObj[section] &&
      modifyItemsObj[section][subSection] &&
      modifyItemsObj[section][subSection]["hidden_tabs"] &&
      modifyItemsObj[section][subSection]["hidden_tabs"].includes(tab)
    ) {
      return true;
    }

    return false;
  },

  isFieldHidden: (section, subSection, field) => {
    if (!modifyItemsObj) return false;

    if (
      modifyItemsObj[section] &&
      modifyItemsObj[section][subSection] &&
      modifyItemsObj[section][subSection]["hidden_fields"] &&
      modifyItemsObj[section][subSection]["hidden_fields"].includes(field)
    ) {
      return true;
    }

    return false;
  },

  isNavigationItemHidden: (section, parentSection) => {
    if (!navigationHiddenItemsObj) orgPermissions.init();
    // if parent section is hidden completely (then its childs are hidden as well)
    if (
      parentSection &&
      navigationHiddenItemsObj?.[parentSection] &&
      is_empty(navigationHiddenItemsObj[parentSection])
    )
      return true;

    for (let key in navigationHiddenItemsObj) {
      if (
        (key === section &&
          navigationHiddenItemsObj[key] && // if it is a independent section and is hidden
          is_empty(navigationHiddenItemsObj[key])) ||
        (!is_empty(navigationHiddenItemsObj[key]) && // if child is in list of items hidden in parent
          navigationHiddenItemsObj[key]?.includes(section))
      )
        return true;
    }
    return false;
  },

  hasFeatureWriteAccess: (feature) => {
    if (orgPermissions.isMainUser()) return true;
    if (is_empty(visibleFeatures)) {
      orgPermissions.init();
    }
    if (!visibleFeatures?.[feature] || !visibleFeatures[feature].allow_create)
      return false;
    return true;
  },

  hasFeatureCompleteVisibility: (feature) => {
    if (orgPermissions.isMainUser()) return true;
    if (is_empty(visibleFeatures)) {
      orgPermissions.init();
    }
    if (
      !visibleFeatures?.[feature] ||
      !visibleFeatures[feature].all_staff_members_access
    )
      return false;
    return true;
  },

  isExportAllowed: (section, subSection) => {
    if (!modifyItemsObj) return true;

    if (
      modifyItemsObj[section] &&
      modifyItemsObj[section][subSection] &&
      modifyItemsObj[section][subSection]["is_export_allowed"]
    ) {
      return modifyItemsObj[section][subSection]["is_export_allowed"];
    }

    return true;
  },

  isViewHidden: () => {
    if (!orgPermissions.rolePowers || !orgPermissions.rolePowers?.role_type) {
      orgPermissions.init();
    }

    if (orgPermissions.rolePowers?.role_type > 3) return true;

    return false;
  },

  isStaff: () => {
    if (!orgPermissions.rolePowers || !orgPermissions.rolePowers?.role_type) {
      orgPermissions.init();
    }

    if (orgPermissions.rolePowers?.role_type > 3) return true;

    return false;
  },

  isMainUser: () => {
    if (!orgPermissions.rolePowers || !orgPermissions.rolePowers?.role_type) {
      orgPermissions.init();
    }

    if (orgPermissions.rolePowers?.role_type == constants.STAFF_ROLES.MAIN)
      return true;

    return false;
  },

  //@DEV this function is async version of orgPermissions.isMainUser
  // currently used in redirectBasedOnCreatorOnboardingStage in src/features/Onboarding/utils/Onboarding.utils.js
  isMainUserAsync: async () => {
    if (!orgPermissions.rolePowers || !orgPermissions.rolePowers?.role_type) {
      await orgPermissions.init();
    }

    if (orgPermissions.rolePowers?.role_type == constants.STAFF_ROLES.MAIN)
      return true;

    return false;
  },

  isOwner: () => {
    if (!orgPermissions.rolePowers || !orgPermissions.rolePowers?.role_type) {
      orgPermissions.init();
    }

    if (orgPermissions.rolePowers?.role_type == constants.STAFF_ROLES.OWNER)
      return true;

    return false;
  },

  isManager: () => {
    if (!orgPermissions.rolePowers || !orgPermissions.rolePowers?.role_type) {
      orgPermissions.init();
    }

    if (orgPermissions.rolePowers?.role_type == constants.STAFF_ROLES.MANAGER)
      return true;

    return false;
  },

  isPrimaryUser: () => {
    if (!orgPermissions.rolePowers || !orgPermissions.rolePowers?.role_type) {
      orgPermissions.init();
    }

    if (
      orgPermissions.rolePowers?.role_type ==
      constants.STAFF_ROLES.PRIMARY_STAFF
    )
      return true;

    return false;
  },

  isSecondaryUser: () => {
    if (!orgPermissions.rolePowers || !orgPermissions.rolePowers?.role_type) {
      orgPermissions.init();
    }

    if (
      orgPermissions.rolePowers?.role_type ==
      constants.STAFF_ROLES.SECONDARY_STAFF
    )
      return true;

    return false;
  },

  getRoleType: () => {
    if (!orgPermissions.rolePowers || !orgPermissions.rolePowers?.role_type) {
      orgPermissions.init();
    }

    return orgPermissions.rolePowers?.role_type;
  },

  canSendEmail: () => {
    return orgPermissions.hasFeatureWriteAccess(
      RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
    );
  },

  canEdit: () => {
    if (!orgPermissions.rolePowers || !orgPermissions.rolePowers?.edit) {
      orgPermissions.init();
    }

    return orgPermissions.rolePowers?.edit || false;
  },

  canCreate: () => {
    if (!orgPermissions.rolePowers || !orgPermissions.rolePowers?.edit) {
      orgPermissions.init();
    }

    return orgPermissions.rolePowers?.edit || false;
  },

  canCreateOffering: () => {
    return orgPermissions.hasFeatureWriteAccess(
      RESOURCE_KEYS.SECTIONS.CREATE_EDIT_LISTINGS
    );
  },

  canDelete: () => {
    if (!orgPermissions.rolePowers || !orgPermissions.rolePowers?.delete) {
      orgPermissions.init();
    }

    return orgPermissions.rolePowers?.delete || false;
  },

  canAssign: () => {
    if (
      !orgPermissions.rolePowers ||
      !orgPermissions.rolePowers?.is_assigment_allowed
    ) {
      orgPermissions.init();
    }

    return orgPermissions.rolePowers?.is_assigment_allowed || false;
  },

  canExport: () => {
    return !orgPermissions.isNavigationItemHidden(
      RESOURCE_KEYS.SECTIONS.EXPORT_DATA_CSC
    );
  },

  canShare: () => {
    if (!orgPermissions.rolePowers || !orgPermissions.rolePowers?.share) {
      orgPermissions.init();
    }

    return orgPermissions.rolePowers?.share || false;
  },

  canToggle: (section, subsection, tab, field) => {
    if (!modifyItemsObj) {
      orgPermissions.init();
    }

    if (
      modifyItemsObj &&
      modifyItemsObj[section] &&
      modifyItemsObj[section][subsection] &&
      modifyItemsObj[section][subsection]["toggle_columns_off"] &&
      modifyItemsObj[section][subsection]["toggle_columns_off"].filter(
        (tabfield) => tabfield[tab] === field
      )[0]
    ) {
      return false;
    }

    return true;
  },
};

export const RESOURCE_KEYS = {
  SECTIONS: {
    CREATE_EDIT_LISTINGS: "create_edit_listing",
    MY_BOOKINGS: "bookings",
    ALL_CUSTOMERS: "all_customers",
    STOREFRONT: "storefront",
    YOUR_WEBSITE: "your_webiste",
    LANDING_PAGES: "landing_pages",
    PAYMENT_PAGES: "payment_pages",
    MANAGE_TRANSACTIONS: "manage_transactions",
    SEO: "seo",
    COMMUNICATIONS: "communications",
    PAYMENT_LINKS: "payment_links",
    DISCOUNT_CODES: "discount_codes",
    LIMITED_TIME_OFFER: "limited_time_offer",
    CUSTOM_MAILS: "custom_mails",
    INTEGRATIONS: "integrations",
    TRAFFIC_ANALYSIS: "traffic_analysis",
    BANK_ACCOUNT_DETAILS: "bank_account_details",
    WHATSAPP_BROADCASTS: "whatsapp_blast",
    DASHBOARD: "dashboard",
    AD_CREATION: "ad_creation",
    EXLY_CONNECT: "exlycon",
    MEDIA_CREATIVES: "creatives",
    MEDIA_LIBRARY: "unlimited_storage",
    INTERNATIONAL_PAYMENTS: "international_payments",
    MANAGE_WEBPAGE: "manage_webpage",
    EXPORT_DATA_CSC: "export_data_csv",
    ORGANIZATION: "organisation",
    LEADS: "my_leads",
    FEEDBACKS: "feedbacks",
    BRAND_PARTNERSHIP: "brand_partnership",
    AFFILIATE_PARTNERSHIP: "affiliate_partnership",
    AFFILIATE_MARKETING: "affiliate_marketing",
    MY_SCHEDULE: "schedule_management",
    ATTENDANCE: "attendance_tracking",
    CERTIFICATES: "certificates",
    REPORTS: "reports",
    BLOGS: "blogs",
    SEGMENTATION: "segmentation",
    ZOOM_ATTENDANCE: "ZOOM_ATTENDANCE",
    VIDEO_DRM: "VIDEO_DRM",
    SALES_PAGES: "SALES_PAGES",
    EXLY_PLUS: "exly_plus",
    INBOX: "inbox",
  },
  QUICK_ONBOARDING: {
    CREATE_WEBSITE: "CREATE_WEBSITE",
    CREATE_AUTOMATION: "CREATE_AUTOMATION",
    ACCEPT_PAYMENT: "ACCEPT_PAYMENT",
    SKIP_THIS_STEP: "SKIP_THIS_STEP",
  },
  SUBSECTIONS: {
    CREATE_EDIT_LISTINGS: {
      CREATE_EDIT_LISTINGS: "create_edit_listing",
    },
    LEADS: {
      WEBPAGE_LEADS: "webpage_leads",
      AD_LEADS: "ad_leads",
    },
    PAYMENT_LINKS: {
      CREATE_PAYMENT_LINKS: "create_payment_links",
      EXISTING_PAYMENT_LINKS: "existing_payment_links",
    },
    INTEGRATIONS: {
      DOMAIN_LINKING: "domain_linking",
      GOOGLE_ANALYTICS: "google_analytics",
      FACEBOOK_PIXEL: "facebook_pixel",
      GOOGLE_CALENDAR: "google_calendar",
    },
    MANAGE_WEBPAGE: {
      CHANGE_TEMPLATE: "change_template",
      WEBPAGE_DETAILS: "webpage_details",
      FAQ: "faq",
    },
    MY_BOOKINGS: {
      BOOKINGS: "bookings",
      CONTENT_PURCHASE: "content_purchase",
      PRODUCT_ORDER_PURCHASE: "product_order_purchase",
    },
    ALL_CUSTOMERS: {
      ALL_CUSTOMERS: "all_customers",
    },
    MANAGE_TRANSACTIONS: {
      ALL_TRANSACTIONS: "all_transaction",
      MANAGE_SUBSCRIPTION: "manage_subscription",
      REFUND_HISTORY: "refund_history",
    },
    COMMUNICATIONS: {
      AUTOMATED_MESSAGES: "automated_message",
      CUSTOMER_COMM_INVOICES: "customer_comm_invoices",
      CUSTOMER_COMM_PURCHASE_CONFIRMATION:
        "customer_comm_purchase_confirmation",
      CUSTOMER_COMM_REMINDERS: "customer_comm_reminders",
      CUSTOMER_COMM_UPDATES: "customer_comm_updates",
      COMM_REPORTS: "comm_reports",
      CREATOR_COMM_PURCHASE_CONFIRMATION: "creator_comm_purchase_confirmation",
      CREATOR_COMM_UPDATES: "creator_comm_updates",
      CREATOR_COMM_REMINDERS: "creator_comm_reminders",
    },
    EXLY_PLUS: {
      REFER_A_FRIEND: "referral_section",
      USER_REFERRALS: "user_referral",
    },
    AFFILIATE: {
      NEW_AFFLIATES: "new_affliates",
      AFFLIATE_TRANSACTIONS: "affliate_transaction",
    },
  },
  TABS: {
    CREATE_EDIT_LISTINGS: {
      CREATE_EDIT_LISTINGS: {
        CREATE_LISTING: "create_listing",
        MANAGE_APPOINTMENT: "manage_appointment",
        PLANS: "plans",
      },
    },
    COMMUNICATIONS: {
      AUTOMATED_MESSAGES: {
        SENT_TO_CREATOR: "sent_to_creator",
        SENT_TO_USER: "sent_to_user",
      },
    },
  },
  FIELDS: {
    MY_BOOKINGS: {
      CONTENT_PURCHASE: {
        PRICE_PAID: "price_paid",
      },
      PRODUCT_ORDER_PURCHASE: {
        PRICE_PAID: "price_paid",
      },
    },
    COMMUNICATIONS: {
      AUTOMATED_MESSAGES: {
        VIEW_EDIT: "view_edit",
      },
    },
    ALL_CUSTOMERS: {
      ALL_CUSTOMERS: {
        TOTAL_SPENT: "total_spent",
        VIEW_ACTIVITY: "view_activity",
      },
    },

    MANAGE_TRANSACTIONS: {
      ALL_TRANSACTIONS: {
        PRICE_PER_SLOT: "price_per_slot",
        TRANSACTION_AMOUNT: "transaction_amount",
        NO_OF_UNITS: "no_of_units",
        PAYOUT_AMOUNT: "payout_amount",
        PAYOUT_DATE: "payout_date",
        PAYOUT_STATUS: "payout_status",
      },
      MANAGE_SUBSCRIPTION: {
        SUBSCRIPTION_AMOUNT: "subscription_amount",
      },
    },
  },
};
