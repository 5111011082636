import { us_and_indian_timezones } from "constants/timezones/AllTimezonesArray";
import moment from "moment";
import constants from "constants/constants";
import { getRAUrlParams } from "./urlUtils";
import { timezone_constants } from "constants/timezones/constants";
import { is_empty } from "./validations";
import { getUserTimezone } from "./AuthUtil";
import {
  DATE_YEAR_MONTH_DAY_TIME_FORMAT,
  DEFAULT_TIME_FORMAT_12,
} from "constants/dateTime";

const { indian_timezone } = constants;

/**
 * to get the Date object in same timezone that is passed by the backend
 * TODO: update implementation with date-fns when moment is phased out
 */
export const parseDateStringForGivenTz = (str) =>
  moment
    .parseZone(str)
    .utcOffset(-1 * new Date().getTimezoneOffset(), true)
    .toDate();

export const getUtcOffsetForGivenTz = (str) =>
  moment.parseZone(str).utcOffset();

export const convertDateObjToGivenTz = (dateObj, utcOffset) =>
  moment.parseZone(dateObj).utcOffset(utcOffset).toDate();

export const date_fns_formats = {
  hour_min_ampm: "hh:mmaaa",
  hour_min__AMPM: "hh:mm a",
};

// This is a bug in chromium that returns Calcutta instead of Kolkata
// This function will just convert it to Asia/Kolkata if that is the case
// Add any further normalisation of timezone parsing here
export const resolveDefaultTimezone = (timezone) =>
  timezone === "Asia/Calcutta" ? indian_timezone : timezone;

/**
 * Provides the system timezone using the window.Intl API.
 * */
export const getBrowserTimezone = () => {
  try {
    return resolveDefaultTimezone(
      new window.Intl.DateTimeFormat().resolvedOptions().timeZone
    );
  } catch (error) {
    return indian_timezone;
  }
};

export const getBrowserTimezoneOption = () => {
  /**
   * Checking from browser query if location is US or India
   */
  const params = getRAUrlParams();
  const queryLocation = params.get("location");
  const isUs = queryLocation === "us";

  const timezone = isUs
    ? timezone_constants.default_internation_timezone
    : getBrowserTimezone(); //if query has us we send default us timezone
  const option = us_and_indian_timezones.find(
    (item) => item.value === timezone
  );
  return option || timezone_constants.default_internation_timezone;
};

export const isTimezoneIndian = (timezone) => {
  if (!timezone) return false;
  const value = resolveDefaultTimezone(timezone);

  /**
   * Checking from browser query if location is US or India
   */
  const params = getRAUrlParams();
  const queryLocation = params.get("location");
  const isUs = queryLocation === "us";

  return value === indian_timezone && !isUs;
};

export const durationFinder = (start_time, end_time) => {
  const startTime = moment(start_time, "LT");
  const endTime = moment(end_time, "LT");
  const duration = moment.duration(endTime.diff(startTime));
  return duration;
};

export const durationAdder = (start_time, duration, range) => {
  const startTime = moment(start_time, "hh:mm:ss A");
  const end_time = startTime.add(duration, range).format("LT");
  return end_time;
};

/**
 *
 * @param {*} value number of days between which the dates are to be filtered
 * @returns start and end dates for range
 */
export const getDateFilterRange = (value) => {
  const endDate = moment().format("YYYY-MM-DD");
  const startdate = moment()
    .subtract(value - 1, "days")
    .format("YYYY-MM-DD");

  return { endDate, startdate };
};
/**
 * returns next moment instance of given weekday
 * @param {number} [weekday]
 * weekday enum :
 * Monday: 1,
 * Tuesday: 2,
 * Wednesday: 3,
 * Thursday: 4,
 * Friday: 5,
 * Saturday: 6,
 * Sunday: 7
 * @param {number} [weekday] default : current weekday
 * @returns {moment} - moment object
 */
export const getNextDateOfWeekday = (weekday = moment().isoWeekday()) => {
  const today = moment().isoWeekday();

  // if we haven't yet passed the day of the week that I need:
  if (today < weekday) {
    // then returns this week's instance of that day
    return moment().isoWeekday(weekday);
  } else {
    // otherwise, returns next week's instance of that same day
    return moment().add(1, "weeks").isoWeekday(weekday);
  }
};

/**
 *
 * @param {*} dateString date value in string
 * @param {*} timeString time value in string
 * @returns  ISOformat for date time
 */
// TODO @quashid to implement it using moment library
export const getISOStringFromDateAndTime = (dateString, timeString) => {
  const dateTimeString = `${dateString} ${timeString}`;
  // Parse the combined string using Moment.js
  const parsedDateTime = moment.tz(
    dateTimeString,
    DATE_YEAR_MONTH_DAY_TIME_FORMAT,
    getUserTimezone()
  );

  // Convert to ISO string keeping timezone offset
  const isoString = parsedDateTime.toISOString(true);

  return isoString;
};

/**
 *
 * @param {*} isoString String coming from backend unparsed
 * @returns object with date and time keys
 */
export function getDateStringAndTime(isoString) {
  // Fallback if start time is empty
  if (is_empty(isoString))
    return {
      dateString: "",
      timeString: "",
    };
  const parsedDateTime = moment(isoString, "YYYY-MM-DD h:mm A");

  // Format the date and time strings
  const dateString = parsedDateTime.format("YYYY-MM-DD");
  const timeString = parsedDateTime.format("h:mm A");

  return {
    dateString,
    timeString,
  };
}

/**
 * Converts an ISO string from the backend to a formatted date and time string.
 *
 * @param {string} value - The value containing the date as an ISO string.
 * @param {boolean} timeRequired - Flag indicating whether to include time in the formatted output.
 * @returns {string} - Formatted date and time string.
 */
export const DateConvert = (value, timeRequired = false) => {
  return moment(value)
    .tz(getUserTimezone())
    .format(
      `${constants.displayDateYearFormat}${
        timeRequired ? `,${DEFAULT_TIME_FORMAT_12}` : ""
      }`
    );
};
